/* eslint-disable */
<template>
	<v-container fluid>
		<v-toolbar elevation="0" class="transparent">
			<v-toolbar-title> Web Apps </v-toolbar-title>
			<v-spacer />
			<v-btn icon @click="fetchWebApps"
				><v-icon>mdi-refresh</v-icon></v-btn
			>
			<v-btn icon><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
			<v-spacer />
		</v-toolbar>
		<v-divider />
		<v-container>
			<v-data-table
				:items="webAppStore.webAppList"
				:headers="headers"
				:loading="loading"
			>
				<template v-slot:[`item.actions`]="{ item }">
					<v-btn icon plain @click="openApp(item.id)"
						><v-icon right>mdi-chevron-right</v-icon></v-btn
					>
				</template>
			</v-data-table>
		</v-container>
	</v-container>
</template>

<script>
	import { useWebAppStore } from "@/store/webapps";
	import WebApp from "@/views/webapp/WebApp";
	export default {
		name: "WebApps",
		components: { WebApp },
		setup() {
			const webAppStore = useWebAppStore();
			return { webAppStore };
		},
		data() {
			return {
				loading: false,
			};
		},
		computed: {
			headers() {
				let headers = [
					{ text: "Id", value: "id" },
					{ text: "Name", value: "name" },
					{ text: "", value: "actions" },
				];
				return headers;
			},
		},
		methods: {
			async fetchWebApps() {
				this.loading = true;
				await this.webAppStore.fetchWebApps();
				this.loading = false;
			},
			openApp(_appId) {
				this.$router.push({
					name: "WebApp",
					params: { id: _appId },
				});
			},
		},
		mounted() {
			this.fetchWebApps();
		},
	};
</script>

